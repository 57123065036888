import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { StructureDepth } from '@shared/utils/school-structure';
import { SchoolStructureControlItem } from '@ui-kit/hes-school-structure-control/school-structure-control-item.interface';
import { map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AnnouncementUserQueryParams,
  AnnouncementUserResponseDTO,
  CountByRoleResponseDTO,
  GetSMSCostDTO,
  PostAnnouncementUnreadCountsResponseDTO,
  PostPayload,
  PostResponseDTO,
  SchoolSctructureEntityDTO,
  UserFeedPayload,
  ViewPostsResponseDTO,
} from './post.dto';
import { SchoolStructureScopeService } from '@core/school-structure-scope.service';
import { FileUploadService } from '@shared/services/file-upload.service';
import { IAttachmentControlValue } from '@ui-kit/hes-attachment-form-control/attachment-control-value.interface';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  private readonly http = inject(HttpClient);
  private readonly schoolStructureScope = inject(SchoolStructureScopeService);
  private readonly fileUploadService = inject(FileUploadService);
  private readonly BE_API_BASE_URL = environment.BE_API_BASE_URL;

  private _postAnnoucementsUnreadCounts = signal<number>(0);
  readonly postAnnoucementsUnreadCounts =
    this._postAnnoucementsUnreadCounts.asReadonly();

  fetchUserFeeds(params: UserFeedPayload) {
    return this.http.get<ViewPostsResponseDTO>(
      `${this.BE_API_BASE_URL}/announcements/user-feed`,
      {
        params,
      },
    );
  }

  viewPost(id: number) {
    return this.http.post(`${this.BE_API_BASE_URL}/announcements/views`, {
      announcementIds: [id],
    });
  }

  upload(attachments: IAttachmentControlValue[]) {
    return this.fileUploadService.uploadMediaFiles({
      urls: {
        videoUrl: `${this.BE_API_BASE_URL}/announcements/video/upload`,
        fileUrl: `${this.BE_API_BASE_URL}/announcements/file/upload`,
      },
      attachments,
    });
  }

  private base64WithoutPrefix(base64String: string) {
    return base64String.split(',')[1];
  }

  deleteImage(key: string) {
    return this.http.delete(
      `${this.BE_API_BASE_URL}/announcements/file/delete/${key}`,
    );
  }

  sendTestSMS(content: string): Observable<void> {
    return this.http.post<void>(
      `${this.BE_API_BASE_URL}/announcements/sms/test`,
      {
        content,
      },
    );
  }
  createPost(data: PostPayload) {
    return this.http.post(
      `${this.BE_API_BASE_URL}/announcements/${data.type}`,
      data,
    );
  }

  updatePost(id: number, data: PostPayload) {
    return this.http.put(
      `${this.BE_API_BASE_URL}/announcements/${data.type}/${id}`,
      data,
    );
  }

  getPostById(id: number, type: 'notification' | 'sms' | 'post') {
    return this.http.get<PostResponseDTO>(
      `${this.BE_API_BASE_URL}/announcements/${type}/${id}`,
    );
  }

  getUnreadPostAnnouncementsCount(academicYearId: number | undefined) {
    return this.http
      .get<PostAnnouncementUnreadCountsResponseDTO>(
        `${this.BE_API_BASE_URL}/announcements/unread-post-counts`,
        academicYearId
          ? {
              params: {
                academicYearId: academicYearId.toString(),
              },
            }
          : undefined,
      )
      .subscribe((res) => {
        this._postAnnoucementsUnreadCounts.set(res.data.unreadCounts);
        return res.data;
      });
  }

  getSchoolStructure() {
    const data =
      this.schoolStructureScope.getUserScopedSchoolStructureTillDepth(
        StructureDepth.CLASS,
      ) as unknown as SchoolStructureControlItem[];
    this.addParentRef(data);
    return data;
  }

  getCountByRole(args: {
    targets: Array<SchoolSctructureEntityDTO>;
    roleIds: number[];
    academicYearId?: number;
  }): Observable<CountByRoleResponseDTO['data']> {
    return this.http
      .post<CountByRoleResponseDTO>(
        `${this.BE_API_BASE_URL}/announcements/user-counts-by-roles`,
        {
          ...args,
        },
      )
      .pipe(map((res) => res.data));
  }

  getSMSCost(params: {
    messageCount: number;
    userCount: number;
  }): Observable<GetSMSCostDTO['data']> {
    return this.http
      .get<GetSMSCostDTO>(`${this.BE_API_BASE_URL}/announcements/sms/cost`, {
        params,
      })
      .pipe(map((res) => res.data));
  }

  getAnouncementUsers(
    params: AnnouncementUserQueryParams,
  ): Observable<AnnouncementUserResponseDTO> {
    return this.http.post<AnnouncementUserResponseDTO>(
      `${this.BE_API_BASE_URL}/announcements/users`,
      {
        ...params,
      },
    );
  }

  private getValidName(name: string) {
    return name?.replace(/[^a-zA-Z0-9.]/g, '')?.replace(/(?!.*\.)(\.)/g, '');
  }

  private addParentRef(
    nodes: SchoolStructureControlItem[],
    parent: SchoolStructureControlItem | null = null,
  ) {
    nodes.forEach((node) => {
      if (parent !== null) {
        node.parent = parent;
      }
      if (node.children) {
        this.addParentRef(node.children, node);
      }
    });
  }
}
